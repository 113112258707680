import React from 'react';

// Styles
import * as style from './index.module.css'

function Connect({
	isBraveBrowser,
	supportsPush,
}) {
	return (
		<section className={ style.Connect }>
			<header className={ style.Header }>
				<hgroup>
					<h3>Get Notified!</h3>
					<p className={ style.Intro }>Want to know when a new scene is released? Here are a few ways you can keep up with Best Horror Scenes.</p>
				</hgroup>
			</header>
			<ul className={ style.Connections }>
				{
					(
						(supportsPush || !supportsPush)
						&& !isBraveBrowser
					) ? (
						<li>
							<h4>Web Notifications (recommended)</h4>
							<p>Receive alerts in your browser when new scenes are posted. Unsubscribe here any time.</p>
							<div className="onesignal-customlink-container" />
						</li>
					) : null
				}
				<li>
					<h4>RSS</h4>
					<p>Do you use an RSS reader? How about a “read later” service? Use the link below to subscribe.</p>
					<a
						className={ style.Button }
						href="/rss.xml"
						onClick={ () => {
							if (window !== 'undefined') {
								window.gtag('event', 'connect', {
									action: 'click',
									value: 'rss',
								});
							}
						} }
					>
						RSS
					</a>
				</li>
				<li>
					<h4>Twitter</h4>
					<p>Follow <a href="https://twitter.com/besthorrorscene">@besthorrorscene</a> on Twitter. A tweet is posted with every new scene.</p>
					<a
						className={ style.Button }
						href="https://twitter.com/besthorrorscene"
						onClick={ () => {
							if (window !== 'undefined') {
								window.gtag('event', 'connect', {
									action: 'click',
									value: 'twitter',
								});
							}
						} }
					>
						@besthorrorscene
					</a>
				</li>
				<li>
					<h4>Newsletter</h4>
					<p>E-mail newsletters are sent out on occasion and include a rollup of the latest scenes.</p>
					<a
						className={ style.Button }
						href="https://confirmsubscription.com/h/d/E638ABACCA3ADA9B"
						onClick={ () => {
							if (window !== 'undefined') {
								window.gtag('event', 'connect', {
									action: 'click',
									value: 'newsletter',
								});
							}
						} }
					>
						E-mail Newsletter
					</a>
				</li>
				<li>
					<h4>YouTube</h4>
					<p>Where it all started. We now have over 4,000 followers.</p>
					<a
						className={ style.Button }
						href="https://www.youtube.com/c/BestHorrorScenes"
						onClick={ () => {
							if (window !== 'undefined') {
								window.gtag('event', 'connect', {
									action: 'click',
									value: 'youtube',
								});
							}
						} }
					>
						YouTube Channel
					</a>
				</li>
				<li>
					<h4>Vimeo</h4>
					<p>YouTube often flags videos with a copyright claim and blocks them. Vimeo is much less strict.</p>
					<a
						className={ style.Button }
						href="https://vimeo.com/channels/besthorrorscenes"
						onClick={ () => {
							if (window !== 'undefined') {
								window.gtag('event', 'connect', {
									action: 'click',
									value: 'vimeo',
								});
							}
						} }
					>
						Vimeo Channel
					</a>
				</li>
			</ul>
		</section>
	)
}

export default Connect
