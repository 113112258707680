import React, {
	useEffect,
	useMemo,
	useState,
} from 'react'
import ReactPlayer from 'react-player'
import { Link } from 'gatsby'
import { useInView } from 'react-intersection-observer'
import Markdown from 'markdown-to-jsx'

// Components
import Connect from '../Connect'

// Graphics
import BlackEye from '../../assets/eye--black.svg'
import holders from '../../holders'

// Styles
import * as style from './index.module.css'

function Article({
	comments,
	content,
	director,
	entryNumber,
	index,
	isBraveBrowser,
	likes,
	poster,
	rating,
	setComment,
	setLike,
	slug,
	standalone,
	suggestedBy,
	supportsPush,
	title,
	url,
	year,
}) {
	const [isPlaying, setIsPlaying] = useState(false);
	const [isLoaded, setIsLoaded] = useState(false)
	const [isSettingLike, setIsSettingLike] = useState(false)
	const [hasVoted, setHasVoted] = useState(true)
	const [ref, inView] = useInView({
		threshold: 0.2,
	})

	const numVotes = likes?.length
	const isLiked = likes.filter(like => like.isLiked)
	const isUnliked = likes.filter(like => !like.isLiked)

	useEffect(() => {
		if (inView) { setIsLoaded(true) }
	}, [inView])

	const entryComments = comments
		? comments.filter(entry => entry.film === entryNumber && entry.isApproved)
		: []

	useEffect(() => {
		// Update when vote has been logged
		if (isSettingLike) {
			setIsSettingLike(false)
			setHasVoted(true)
		}
	}, [likes])

	useEffect(() => {
		if (typeof window !== 'undefined') {
			const lsValues = JSON.parse(window.localStorage.getItem('bhsLikes'));
			const hasFilmId = lsValues ? lsValues.filter(like => like.filmId === entryNumber) : []
			setHasVoted(hasFilmId.length !== 0)
		}
	}, [])

	const holder = useMemo(() => holders[Math.floor(Math.random() * holders.length)], []);

	return (
		<>
			<article
				className={ style.Article }
				id={ `entry-${index}` }
				key={ slug }
				ref={ ref }
			>
				<header className={ style.ArticleHeader }>
					<h2>
						{
							!standalone ? (
								<Link className={ style.LinkIcon } to={ slug }>#</Link>
							) : null
						}
						{ title } ({ year })
					</h2>
					<div className={ style.Director }>
						<h3><span>Directed by</span> <span>{ director }</span></h3>
					</div>
				</header>
				<div className={ style.PlayerWrapper }
					style={ {
						backgroundImage: `url("/${holder}")`,
					} }
				>
					<div className={ isPlaying ? [style.EntryNumber, style.EntryNumber__isPlaying].join(' ') : style.EntryNumber }>
						<div className={ style.EntryNumberShadow } />
						<span>
							<sup>#</sup>{ entryNumber }
						</span>
					</div>
					<div className={ isSettingLike ? [style.Voting, style.Voting__updating].join(' ') : style.Voting }>
						<button
							className={ style.Vote }
							disabled={ hasVoted }
							onClick={ () => {
								setIsSettingLike(true)
								setLike(entryNumber, true)
								if (window !== 'undefined') {
									window.gtag('event', 'vote', {
										action: 'Agree',
										label: title,
									});
								}
							} }
						>
							&#8593;
						</button>
						<div
							className={ style.VotingResults }
							title={ `${numVotes} vote${numVotes === 1 ? '' : 's'}` }
						>
							{ isLiked.length - isUnliked.length }
						</div>
						<button
							className={ style.Vote }
							disabled={ hasVoted }
							onClick={ () => {
								setIsSettingLike(true)
								setLike(entryNumber, false)
								if (window !== 'undefined') {
									window.gtag('event', 'vote', {
										action: 'Disagree',
										label: title,
									});
								}
							} }
						>
							&#8595;
						</button>
					</div>
					{
						inView || isLoaded ? (
							<ReactPlayer
								controls
								className={ style.ReactPlayer }
								height="100%"
								light={ `/posters/${ poster }` }
								onEnded={
									() => {
										setIsPlaying(false);
										if (window !== 'undefined') {
											window.gtag('event', 'video', {
												action: 'Ended',
												label: title,
												value: entryNumber,
											});
										}
									}
								}
								onPause={
									() => {
										setIsPlaying(false);
										if (window !== 'undefined') {
											window.gtag('event', 'video', {
												action: 'Pause',
												label: title,
												value: entryNumber,
											});
										}
									}
								}
								onPlay={
									() => {
										setIsPlaying(true);
										if (window !== 'undefined') {
											window.gtag('event', 'video', {
												action: 'Play',
												label: title,
												value: entryNumber,
											});
										}
									}
								}
								onSeek={
									() => {
										setIsPlaying(false);
										if (window !== 'undefined') {
											window.gtag('event', 'video', {
												action: 'Seek',
												label: title,
												value: entryNumber,
											});
										}
									}
								}
								onStart={
									() => {
										setIsPlaying(true);
										if (window !== 'undefined') {
											window.gtag('event', 'video', {
												action: 'Start',
												label: title,
												value: entryNumber,
											});
										}
									}
								}
								url={ url }
								width="100%"
							/>
						) : null
					}
				</div>
				<div className={ style.Content }>
					{ suggestedBy ? (
						<p className={ style.SuggestedBy }>
							<BlackEye />
							Suggested by <Markdown>{ suggestedBy }</Markdown>
						</p>
					) : null }
					<div className={ style.Thoughts } >
						{
							content ? (
								<div dangerouslySetInnerHTML={ { __html: content } } />
							) : null
						}
						<div className={ content ? style.ThoughtsFooter : style.ThoughtsFooter__noContent }>
							{ content ? <p>— B</p> : null }
							{ rating ? (
								<div className={ style.MyRating }>
									My rating: {rating}/10
								</div>
							) : null }
						</div>
					</div>
				</div>
				<section className={ style.Comments }>
					<header className={ entryComments?.length ? [style.CommentsHeader, style.CommentsHeader__withContent].join(' ') : style.CommentsHeader }>
						<h4 className={ style.CommentsHeading }>{ entryComments?.length || 'No' } Comment{ entryComments?.length === 1 ? '' : 's' }</h4>
						<button
							className={ style.LeaveComment }
							onClick={ () => {
								if (window !== 'undefined') {
									window.gtag('event', 'comment', {
										action: 'Open',
										label: title,
									});
								}
								setComment(entryNumber)
							} }
						>
							Leave a Comment
						</button>
					</header>
					{
						entryComments?.length ? (
							<div className={ style.UserCommentContainer }>
								<div className={ style.UserComments }>
									{
										entryComments.map(entry => {
											const id = entry._id.id.toString()
											const entryDate = entry.date ? new Date(entry.date).toLocaleDateString(
												'en-gb',
												{
													year: 'numeric',
													month: 'short',
													day: 'numeric'
												}
											) : null
											return (
												<article
													className={ style.UserComment }
													key={ id }
												>
													<Markdown className={ style.UserCommentContent }>{ entry.comment }</Markdown>
													<header>
														<strong>{ entry.name }<br /></strong>
														{
															entry.rating ? (
																<span>
																	Rating: { entry.rating }/10
																	<br />
																</span>
															) : null
														}
														{ entryDate ? <span>{ entryDate }</span> : null }
													</header>
												</article>
											)
										})
									}
								</div>
							</div>
						) : null
					}
				</section>
			</article>
			{
				(
					index % 10 === 0
					&& index != 0
				) ? (
					<Connect
						isBraveBrowser={ isBraveBrowser }
						supportsPush={ supportsPush }
					/>
				) : null
			}
		</>
	)
}

export default Article
