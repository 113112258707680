// extracted by mini-css-extract-plugin
export var Article = "index-module--Article--Hjn11";
export var ArticleHeader = "index-module--ArticleHeader--BjqWC";
export var Comments = "index-module--Comments--9nihR";
export var CommentsHeader = "index-module--CommentsHeader--3f+GE";
export var CommentsHeader__withContent = "index-module--CommentsHeader__withContent--cYOgT index-module--CommentsHeader--3f+GE";
export var CommentsHeading = "index-module--CommentsHeading--XAtub";
export var Content = "index-module--Content--Npe7w";
export var Director = "index-module--Director--c2fKE";
export var EntryNumber = "index-module--EntryNumber--MxwXv";
export var EntryNumberShadow = "index-module--EntryNumberShadow--zwNag";
export var EntryNumber__isPlaying = "index-module--EntryNumber__isPlaying--AXYuv";
export var LeaveComment = "index-module--LeaveComment--vWcNA";
export var LinkIcon = "index-module--LinkIcon---Wx05";
export var MyRating = "index-module--MyRating--317ig";
export var PlayerWrapper = "index-module--PlayerWrapper--TVne-";
export var Rating = "index-module--Rating--fJOCN";
export var ReactPlayer = "index-module--ReactPlayer--iMl1E";
export var SuggestedBy = "index-module--SuggestedBy--pNzSU";
export var Thoughts = "index-module--Thoughts--IsdFP";
export var ThoughtsFooter = "index-module--ThoughtsFooter--Xpwck";
export var ThoughtsFooter__noContent = "index-module--ThoughtsFooter__noContent--YfSx6 index-module--ThoughtsFooter--Xpwck";
export var UserComment = "index-module--UserComment--aFKSE";
export var UserCommentContainer = "index-module--UserCommentContainer--kXACI";
export var UserCommentContent = "index-module--UserCommentContent--qS-o-";
export var UserComments = "index-module--UserComments--NA64c";
export var Vote = "index-module--Vote--RlDzJ";
export var Voting = "index-module--Voting--sJrQN";
export var VotingResults = "index-module--VotingResults--qBxTN";
export var Voting__updating = "index-module--Voting__updating--tkbin";
export var fadeIn = "index-module--fadeIn--EED4z";